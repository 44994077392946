import axios from "axios";

/**
 * Call Api for GET, PUT, DELETE
 * @param {string} method
 * @param {string} route
 * @param {string} id
 * @param {*} body
 * @param {string?} refreshToken
 */
const CallApi = async (method, route, id, body, refreshToken, useArray = true) => {
  const finalResponse = [];
  try {
    let res = await axios({
      method: method,
      headers: {
        "content-type": "application/json",
        Authorization: refreshToken,
        refreshToken: refreshToken
      },
      url: `/api/${route}/${id}`,
      data: body,
    });
    if (!useArray) {
      return res.data
    } finalResponse.push(res.data);
  } catch (e) {
    return { success: false, ...e }
  }
  return finalResponse;
};

export default CallApi;
