import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Header = data => {
  const street = `${data.info.streetNumber ? data.info.streetNumber : ''} ${
    data.info.street
  } `;

  return (
    <header>
      <div id="book">
        <h1>
          <FormattedMessage
            id="common.title"
            defaultMessage="Réservez votre table"
          />
        </h1>
      </div>
      <div id="restaurant">
        <div className="info">
          <div>
            <h2>{data.info.nameRestaurant}</h2>
            {data.slogan ? <h4 className="slogan">{data.info.slogan}</h4> : ''}
            <p>
              {street && street !== ' null ' ? street : ''}
              {data.info.additionalAddress &&
              data.info.additionalAddress !== ' null '
                ? '- ' + data.info.additionalAddress
                : ''}
            </p>

            <p>
              {data.info.zipcode ? data.info.zipcode : ''}{' '}
              {data.info.city ? data.info.city : ''}
              {data.info.country ? ` - ${data.info.country}` : ''}
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  info: PropTypes.object.isRequired
};
export default Header;
