import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import {
	HashRouter as Router,
	Redirect,
	NavLink,
	Switch,
} from 'react-router-dom';
import PropsRoute from './../../utils/PropsRoute';

import PickDate from './PickDate';
import PickTime from './PickTime';
import NbGuest from './NbGuest';
import Contact from './Contact';
import LegalInformation from './LegalInformation';
import Validation from './Validation';
import displayWordingNbGuest from '../../utils/DisplayWordingNbGuest';
import AdminInfo from './AdminInfo';

class TemplateMobile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			info: {
				nameRestaurant: props.info.nameRestaurant,
				restaurantId: props.info.restaurantId,
				streetNumber: props.info.streetNumber,
				address1: props.info.address1,
				address2: props.info.address2,
				zipCode: props.info.zipCode,
				city: props.info.city,
				phone: props.info.phone,
				slogan: props.info.slogan,
				closingDays: props.info.closingDays,
				openingHours: props.info.openingHours,
				//openingHoursOfDay: [],
				validationDelay: props.info.validationDelay,
				email: props.info.email,
				disableAppPeriod: props.info.disableAppPeriod,
				disableApp: props.info.disableApp,
				specificInformation: props.specificInformation,
				timezone: props.info.timezone,
			},
			mobilePeriod: props.mobilePeriod,
			closeDays: props.closeDay,
			menu: {
				day: props.menu.day,
				time: props.menu.time,
				nbGuest: props.menu.nbGuest,
				contact: props.menu.contact,
				send: props.menu.send,
			},
			active: {
				day: false,
				time: false,
				diner: false,
				contact: false,
				legalInformation: false,
			},
			displayMenu: props.displayMenu,
			orderIsBooked: props.orderIsBooked,
			slot_duration: 15,
			slot_freeze: {},
		};
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.info !== this.state.info ||
			nextProps.mobilePeriod !== this.state.mobilePeriod ||
			nextProps.closeDay !== this.state.closeDays ||
			nextProps.menu !== this.state.menu ||
			nextProps.active !== this.state.active ||
			nextProps.displayMenu !== this.state.displayMenu
		) {
			this.setState({
				mobilePeriod: nextProps.mobilePeriod,
				closeDay: nextProps.closeDay,
				menu: nextProps.menu,
				active: nextProps.active,
				info: nextProps.info,
				displayMenu: nextProps.displayMenu,
			});
		}
	}

	strToDate = date => {
		if (date === 'Date') {
			return 'Date';
		}
		return moment(date).format('DD-MM-YYYY').toString();
	};

	handleCick = e => {
		e.preventDefault();
	};

	onUpdateDate = val => {
		this.props.onUpdateDate(val);
	};

	onUpdateMobileDatePeriod = val => {
		this.props.onUpdateMobileDatePeriod(val);
	};

	onUpdateTime = val => {
		this.props.onUpdateTime(val);
	};

	onUpdateNbGuest = val => {
		this.props.onUpdateNbGuest(val);
	};

	onUpdateContact = val => {
		this.props.onUpdateContact(val);
	};

	onUpdateLegalInformation = () => {
		this.setState({
			displayMenu: false,
		});
		this.props.onUpdateLegalInformation();
	};

	onUpdatesendBooking = () => {
		this.props.sendBooking();
	};

	render() {
		const menu = this.state.menu;

		const circle = name => {
			return (
				<div className="icon-menu">
					<span className="circle" id={name} />
				</div>
			);
		};
		return (
			<div id="template-mobile">
				<nav>
					<AdminInfo />
					<ul id="menu">
						<li className={!!this.state.displayMenu ? '' : 'hide'}>
							<NavLink
								activeClassName={'arrow-right active'}
								className={!!this.state.active.day ? 'stay-active' : ''}
								to="/date">
								{circle('date')}
								<p>
									{menu.day ? (
										moment(menu.day).format('DD-MM-YYYY').toString()
									) : (
										<FormattedMessage
											id="menu.client-date"
											defaultMessage="Le jour"
										/>
									)}
								</p>
							</NavLink>
						</li>
						<div
							className={`date ${
								!!this.state.active.day ? 'content' : 'no-select'
							}`}>
							<Switch>
								<Redirect exact from="/" to="/date" />
								<PropsRoute
									path="/date"
									component={PickDate}
									onUpdate={this.onUpdateDate}
									closingDays={this.state.info.closingDays}
									openingHours={this.state.info.openingHours}
									userDay={this.state.menu.day}
									mobilePeriod={this.state.mobilePeriod}
									onUpdateMobileDatePeriod={this.onUpdateMobileDatePeriod}
									validationDelay={this.state.info.validationDelay}
									closeDay={this.props.closeDay}
									disableAppPeriod={this.state.info.disableAppPeriod}
									disableApp={this.state.info.disableApp}
								/>
							</Switch>
						</div>
						<li
							className={`link-hours ${
								!!this.state.displayMenu ? '' : 'hide'
							}`}>
							{menu.day ? (
								<NavLink
									activeClassName={'arrow-right active'}
									className={!!this.props.active.time ? 'stay-active' : ''}
									to="/heure">
									{circle('time')}
									<p>
										{menu.time ? (
											menu.time
										) : (
											<FormattedMessage
												id="menu.client-time"
												defaultMessage="Heure"
											/>
										)}
									</p>
								</NavLink>
							) : (
								<a>
									{circle('time')}
									<p>
										<FormattedMessage
											id="menu.client-time"
											defaultMessage="Heure"
										/>
									</p>
								</a>
							)}
						</li>
						<div className={!!this.state.active.time ? 'content' : 'no-select'}>
							<PropsRoute
								path="/heure"
								component={PickTime}
								onUpdate={this.onUpdateTime}
								userTime={this.state.menu.time}
								openingHours={this.state.info.openingHours}
								userDay={this.state.menu.day}
								mobilePeriod={this.state.mobilePeriod}
								validationDelay={this.state.info.validationDelay}
								disableAppPeriod={this.state.info.disableAppPeriod}
								disableApp={this.state.info.disableApp}
								slotFreeze={this.props.slotFreeze || {}}
							/>
						</div>
						<li className={!!this.state.displayMenu ? '' : 'hide'}>
							{menu.time ? (
								<NavLink
									activeClassName={'arrow-right active'}
									className={!!this.state.active.nbGuest ? 'stay-active' : ''}
									to="/couverts">
									{circle('diners')}
									<p>
										{menu.nbGuest ? (
											displayWordingNbGuest()
										) : (
											<FormattedMessage
												id="menu.client-diner"
												defaultMessage="Couverts"
											/>
										)}
									</p>
								</NavLink>
							) : (
								<a>
									{circle('diners')}
									<p>
										<FormattedMessage
											id="menu.client-diner"
											defaultMessage="Couverts"
										/>
									</p>
								</a>
							)}
						</li>
						<div
							className={!!this.state.active.nbGuest ? 'content' : 'no-select'}>
							<PropsRoute
								path="/couverts"
								component={NbGuest}
								onUpdate={this.onUpdateNbGuest}
								userDiner={this.state.menu.nbGuest}
								maxGuests={this.props.maxGuests}
							/>
						</div>
						<li className={!!this.state.displayMenu ? '' : 'hide'}>
							{menu.nbGuest ? (
								<NavLink
									activeClassName={'arrow-right active'}
									className={!!this.state.active.contact ? 'stay-active' : ''}
									to="/contact">
									{circle('contact')}
									<p>
										{menu.contact ? (
											menu.contact
										) : (
											<FormattedMessage
												id="menu.client-contact"
												defaultMessage="Coordonnées"
											/>
										)}
									</p>
								</NavLink>
							) : (
								<a>
									{circle('contact')}
									<p>
										<FormattedMessage
											id="menu.client-contact"
											defaultMessage="Coordonnées"
										/>
									</p>
								</a>
							)}
						</li>
						<div
							className={` ${
								!!this.state.active.contact ? 'content' : 'no-select'
							}`}>
							<div className="contact-content">
								<PropsRoute
									path="/contact"
									component={Contact}
									onUpdate={this.onUpdateContact}
									val={this.state.contact}
									menu={this.state.menu}
									restaurantId={this.state.info.restaurantId}
									nameRestaurant={this.state.info.nameRestaurant}
								/>
							</div>
						</div>
						<li
							className={`${!!this.state.displayMenu ? '' : 'hide'} ${
								!!this.state.active.contact ? 'stay-active' : ''
							}`}>
							{menu.contact ? (
								<NavLink
									to="/legalInformation"
									activeClassName={'arrow-right active'}
									className={
										!!this.props.active.legalInformation ? 'stay-active' : ''
									}>
									{circle('validation')}
									<p>
										{menu.send ? (
											menu.send
										) : (
											<FormattedMessage
												id="menu.client-send"
												defaultMessage="Valider"
											/>
										)}
									</p>
								</NavLink>
							) : (
								<a>
									{circle('validation')}
									<p>
										<FormattedMessage
											id="menu.client-send"
											defaultMessage="Confirmer"
										/>
									</p>
								</a>
							)}
						</li>
						<div className={`contact-content`}>
							<div
								className="no-select"
								/* className={`${
									!!this.state.active.contact ? 'content' : 'no-select'
								}`} */
							>
								<PropsRoute
									path="/legalInformation"
									component={LegalInformation}
									onUpdate={this.onUpdateLegalInformation}
									val={this.state.contact}
									menu={this.state.menu}
									restaurantId={this.state.info.restaurantId}
									nameRestaurant={this.state.info.nameRestaurant}
									specificInformation={this.state.info.specificInformation}
									timezone={this.state.info.timezone}
									orderIsBooked={this.props.orderIsBooked}
								/>
							</div>
						</div>
					</ul>
					<PropsRoute
						path="/validation"
						component={Validation}
						nameRestaurant={this.state.info.nameRestaurant}
						clearLocalStorage={this.props.clearLocalStorage}
						sendBooking={this.onUpdatesendBooking}
					/>
				</nav>
			</div>
		);
	}
	static defaultProps = {
		info: {
			nameRestaurant: '',
			restaurantId: '',
			streetNumber: '',
			address1: '',
			address2: '',
			zipCode: '',
			city: '',
			phone: '',
			slogan: '',
			closingDays: [],
			openingHours: {},
			validationDelay: 0,
			email: '',
			disableAppPeriod: '',
			disableApp: false,
		},
		menu: {
			day: '',
			time: '',
			diner: '',
			contact: '',
			send: '',
		},
		active: {
			day: false,
			time: false,
			diner: false,
			contact: false,
			legalInformation: false,
		},
		restaurantId: '',
		displayMenu: true,
		closingDays: [],
		mobilePeriod: '',
	};
}

TemplateMobile.propTypes = {
	menu: PropTypes.object.isRequired,
	active: PropTypes.object.isRequired,
	restaurantId: PropTypes.string.isRequired,
	display: PropTypes.bool,
	info: PropTypes.object.isRequired,
	mobilePeriod: PropTypes.string,
	closeDay: PropTypes.array,
	displayMenu: PropTypes.bool,
	onUpdateDate: PropTypes.func.isRequired,
	onUpdateMobileDatePeriod: PropTypes.func.isRequired,
	onUpdateTime: PropTypes.func.isRequired,
	onUpdateNbGuest: PropTypes.func.isRequired,
	onUpdateContact: PropTypes.func.isRequired,
	onUpdateLegalInformation: PropTypes.func.isRequired,
};

export default TemplateMobile;
