import React, { Component } from 'react';
import './../styles/Admin.scss';
import { HashRouter as Router, Switch, Redirect } from 'react-router-dom';
import CallApi from './../utils/CallApi';
import Template from './../components/admin/Template';
import PropsRoute from './../utils/PropsRoute';
import OpeningHoursV2 from '../components/admin/OpeningHoursV2';
import Settings from './../components/admin/Settings';
import Informations from './../components/admin/Informations';
import Integration from './../components/admin/Integration';
import EmailValidation from './../components/admin/EmailValidation';
import NoRecoveryRestaurantId from './../components/admin/NoRecoveryRestaurantId';
import { SnackbarProvider } from 'notistack';
import Header from './../components/admin/Header';
import decode from 'jwt-decode';
import Loader from 'react-loader-spinner';
import { FormattedMessage } from 'react-intl';
import { getCookie } from '../utils/function';
import { UnauthorizedServicePage } from "@capps/subuser-tools";

class IndexAdmin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token:
				getCookie('refreshToken') ||
				window.sessionStorage.getItem('accessToken') ||
				'',
			restaurantId: '',
			openingHours: {},
			contact: {
				nameRestaurant: '',
				name: '',
				slogan: '',
				email: '',
				streetNumber: 0,
				street: '',
				additionalAddress: '',
				zipcode: '',
				city: '',
				phone: '',
				country: '',
				timezone: '',
			},
			closingDays: [],
			validationDelay: 0,
			tokenLaddition: '',
			disableApp: '',
			disableAppPeriod: '',
			specificInformation: '',
			loader: false,
			createAccountError: false,
			alias: '',
			nbrGuests: null,
			rulesStatus: '',
			hasECGroup: false,
			unauthorizedSubUser: false,
		};
	}

	componentWillMount() {
		document.body.setAttribute('id', 'admin');
	}

	componentWillUnmount() {
		document.body.setAttribute('id', '');
	}

	async componentDidMount() {
		const token = getCookie('refreshToken');
		let decoded;

		if (token) {
			decoded = decode(token);
		}
		if (decoded) {
			const tokenMail = decoded.email || decoded.username;
			try {
				this.setState({
					loader: true,
				});
				const res = await CallApi('get', 'restaurants', decoded.id, '', token);
				if (res[0].emailLaddition !== tokenMail) {
					await CallApi('put', 'restaurants', res[0].id, {
						emailLaddition: tokenMail,
						emailContact:
							res[0].emailContact === res[0].emailLaddition
								? tokenMail
								: res[0].emailContact,
					});
				}

				const rulesStatus = await CallApi(
					'GET',
					'rulesService',
					res[0].id,
					{},
					token
				);
				this.setState({
					...this.state,
					restaurantId: res[0].id,
					closingDays: res[0].closingDays,
					validationDelay: res[0].validationDelay,
					openingHours: res[0].openingDate.monday
						? res[0].openingDate
						: JSON.parse(res[0].openingDate),
					tokenLaddition: res[0].token,
					contact: {
						nameRestaurant: res[0].name,
						name: res[0].name,
						slogan: res[0].slogan,
						email: res[0].emailContact,
						streetNumber:
							res[0].address && res[0].address.streetNumber
								? res[0].address.streetNumber
								: '',
						street:
							res[0].address && res[0].address.street
								? res[0].address.street
								: '',
						additionalAddress:
							res[0].address && res[0].address.additionalAddress
								? res[0].address.additionalAddress
								: '',
						zipcode:
							res[0].address && res[0].address.zipcode
								? res[0].address.zipcode
								: '',
						city:
							res[0].address && res[0].address.city ? res[0].address.city : '',
						phone: res[0].phone,
						country:
							res[0].address && res[0].address.country
								? res[0].address.country
								: '',
						timezone: res[0].timezone ? res[0].timezone : '',
					},
					disableApp: res[0].disableApp,
					disableAppPeriod: res[0].disableAppPeriod
						? JSON.parse(res[0].disableAppPeriod)
						: res[0].disableAppPeriod,
					specificInformation: res[0].specificInformation,
					alias: res[0].alias,
					nbrGuests: res[0].nbrGuests,
					loader: false,
					rulesStatus:
						rulesStatus.length && rulesStatus[0].success
							? rulesStatus[0].data.state
							: 'error',
				});
				const accessToken = window.sessionStorage.getItem('accessToken');
				if (accessToken) {
					const decodedToken = decode(
						window.sessionStorage.getItem('accessToken')
					);
					const ECGroup = decodedToken.groups.some(
						g => g.name === 'espace_client'
					);
					if (ECGroup && !this.state.hasECGroup) {
						this.setState({ hasECGroup: true });
					}
				}
			} catch (e) {
				this.setState({
					errors: (
						<FormattedMessage
							id="emailValidation.errorPostApi"
							defaultMessage="Oups, nous rencontrons actuellement un problème de communication avec les services l'addition."
						/>
					),
					createAccountError: true,
					loader: false,
				});
			}
		}
	}


	onUpdateOpeningHours = val => {
		this.setState({ openingHours: val });
	};

	onUpdateContact = val => {
		let { ...state } = this.state;
		state.contact = {
			...this.state.contact,
			name: val.name,
			slogan: val.slogan,
			streetNumber: val.streetNumber,
			street: val.street,
			additionalAddress: val.additionalAddress,
			zipcode: val.zipcode,
			city: val.city,
			phone: val.phone,
			country: val.country,
			email: val.email,
			timezone: val.timezone,
		};
		this.setState(state);
	};

	onUpdateDelay = val => {
		let { ...state } = this.state;
		state.validationDelay = val;

		this.setState(state);
	};

	onUpdateInfo = val => {
		let { ...state } = this.state;
		state.specificInformation = val;

		this.setState(state);
	};

	onUpdateTokenLaddition = val => {
		let { ...state } = this.state;
		state.tokenLaddition = val;
		this.setState(state);
	};

	onUpdateClosingDays = val => {
		let { ...state } = this.state;
		state.closingDays = val;
		this.setState(state);
	};

	onUpdateUrl = val => {
		let { ...state } = this.state;
		state.alias = val;
		this.setState(state);
	};

	onUpdateNbrGuest = val => {
		let { ...state } = this.state;
		state.nbrGuests = val;
		this.setState(state);
	};

	onUpdateDisableApp = (disableApp, disableAppPeriod) => {
		let { ...state } = this.state;
		state.disableApp = disableApp;
		state.disableAppPeriod = disableAppPeriod;
		this.setState(state);
	};

	getStatus = (step, disabled) => {
		if (disabled) {
			return 'disabled';
		}
		if (step === 4) {
			return 'active';
		}
		return `configuration-${step}.4`;
	};

	onUpdateRulesStatus = async status => {
		const { rulesStatus } = this.state;
		const refreshToken = getCookie('refreshToken')
		if (
			rulesStatus === 'error' ||
			(rulesStatus === 'disabled' && status !== 'activate')
		) {
			return;
		}
		const { name, zipcode, city, phone, email } = this.state.contact;
		const mandatoryContactInfos = name && zipcode && city && phone && email;
		let step = 1;
		if (mandatoryContactInfos) {
			step += 1;
		}
		if (Object.values(this.state.openingHours).some(day => day.length)) {
			step += 1;
		}
		if (this.state.tokenLaddition) {
			step += 1;
		}
		const newStatus = this.getStatus(step, status === 'disabled');
		if (newStatus === rulesStatus) {
			return;
		}
		const update = await CallApi(
			'PUT',
			'rulesService',
			this.state.restaurantId,
			{ service: 'reservation', state: newStatus },
			refreshToken
		);
		this.setState({ ...this.state, rulesStatus: newStatus });
		if (update.length && update[0].statusCode === 201) {
			await CallApi(
				'POST',
				'rulesService',
				this.state.restaurantId,
				{ service: 'reservation', ids: { id: this.state.restaurantId } },
				refreshToken
			);
		}
	};

	render() {
		if (this.state.loader && !this.state.createAccountError) {
			return (
				<div id="loader">
					<Loader type="ThreeDots" color="#e84249" height="45" width="45" />
				</div>
			);
		}

		if (this.state.unauthorizedSubUser) {
			return <UnauthorizedServicePage />;
		}

		if (this.state.restaurantId) {
			return (
				<Router>
					<React.Fragment>
						<SnackbarProvider max={3}>
							<Header
								title="config"
								displayMenu
								hasECGroup={this.state.hasECGroup}
								restaurantId={this.state.restaurantId}
							/>
							<div className="content-admin">
								<Switch>
									<Redirect exact from="/" to="/informations" />
									<PropsRoute
										path="/bookingValidation/:id"
										component={Template(EmailValidation, {
											title: 'Votre validation a bien été pris en compte',
											classNames: 'container',
										})}
									/>
									<PropsRoute
										path="/informations"
										component={Template(Informations, {
											title: 'Configuration de votre module de réservation',
											classNames: 'container',
										})}
										token={this.state.token}
										contact={this.state.contact}
										openingHours={this.state.openingHours}
										onUpdateContact={this.onUpdateContact}
										restaurantId={this.state.restaurantId}
										onUpdateRulesStatus={this.onUpdateRulesStatus}
									/>
									<PropsRoute
										path="/horaires"
										component={Template(OpeningHoursV2, {
											title: `Horaires d'ouverture`,
											classNames: 'container',
										})}
										openingHours={this.state.openingHours}
										restaurantId={this.state.restaurantId}
										onUpdate={this.onUpdateOpeningHours}
										token={this.state.token}
										onUpdateRulesStatus={this.onUpdateRulesStatus}
									/>
									<PropsRoute
										path="/reglages"
										component={Template(Settings, {
											title: `Réglages de votre module de réservation`,
											classNames: 'container',
										})}
										restaurantId={this.state.restaurantId}
										closingDays={this.state.closingDays}
										validationDelay={this.state.validationDelay}
										token={this.state.token}
										tokenLaddition={this.state.tokenLaddition}
										onUpdateDelay={this.onUpdateDelay}
										onUpdateClosingDays={this.onUpdateClosingDays}
										specificInformation={this.state.specificInformation}
										onUpdateInfo={this.onUpdateInfo}
										onUpdateNbrGuest={this.onUpdateNbrGuest}
										nbrGuests={this.state.nbrGuests}
									/>
									<PropsRoute
										path="/integration"
										component={Template(Integration, {
											title: `Publication du module de réservation`,
											classNames: 'container',
										})}
										onUpdateTokenLaddition={this.onUpdateTokenLaddition}
										disableAppPeriod={this.state.disableAppPeriod}
										onUpdateDisableApp={this.onUpdateDisableApp}
										tokenLaddition={this.state.tokenLaddition}
										openingHours={this.state.openingHours}
										restaurantId={this.state.restaurantId}
										disableApp={this.state.disableApp}
										onUpdateUrl={this.onUpdateUrl}
										alias={this.state.alias}
										token={this.state.token}
										onUpdateRulesStatus={this.onUpdateRulesStatus}
									/>
								</Switch>
							</div>
						</SnackbarProvider>
					</React.Fragment>
				</Router>
			);
		} else if (this.state.createAccountError) {
			return <NoRecoveryRestaurantId />;
		} else {
			return <p className="hidden">empty</p>;
		}
	}
}

export default IndexAdmin;
