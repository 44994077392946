import React, { Component } from 'react';
import CalendarUi from './CalendarUi';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Popup from './../common/Popup';
import moment from 'moment';

const today = moment().format('YYYY-MM-DD');
const nextDay = moment().add(1, 'days');
const tomorrow = moment(nextDay).format('YYYY-MM-DD');

class PickDate extends Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      selectDay: '',
      userDay: props.userDay,
      mobilePeriod: props.mobilePeriod,
      disableToday: false,
      disableTomorrow: false,
      disableMorning: false,
      disableEvening: false,
      mobileNextDate: false,
      openingHours: props.openingHours,
      closeDay: props.closeDay, // days where restaurants are close, depending opening hours
      validationDelay: props.validationDelay,
      closingDays: props.closingDays, // periode where restaurant are close (exceptionally)
      lastHoursAvailable: '',
      lastHoursAvailableMorning: '',
      error: '',
      disableAppPeriod: props.disableAppPeriod,
      disableApp: props.disableApp,
      lessTimes: 0
    };
  }

  static getDerivedStateFromProps(next, prev) {
    if (
      next.openingHours != prev.openingHours ||
      next.closingDays !== prev.closingDays ||
      next.closeDay !== prev.closeDay ||
      next.validationDelay !== prev.validationDelay
    ) {
      return {
        validationDelay: next.validationDelay,
        openingHours: next.openingHours,
        closingDays: next.closingDays,
        closeDay: next.closeDay
      };
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userDay !== this.state.selectDay) {
      this.selectDayMenuHoursIsStillAvailable(nextProps.userDay);
    }

    if (nextProps.openingHours != this.state.openingHours) {
      return {
        openingHours: nextProps.openingHours
      };
    }
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidMount() {
    this.onMobileTomorrowAndTodayAreDisabled();
    this.setState({
      selectPeriod: ''
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.closingDays !== this.state.closingDays ||
      prevState.closeDay !== this.state.closeDay
    ) {
      this.onMobileTomorrowAndTodayAreDisabled();
    }
  }

  onMobileTomorrowAndTodayAreDisabled = () => {
    const { closingDays, closeDay } = this.state;
    const today = moment().format('YYYY-MM-DD');
    const nextDay = moment().add(1, 'days');
    const tomorrow = moment(nextDay).format('YYYY-MM-DD');
    const tomorrowName = this.formattedDay(tomorrow);
    const todayName = this.formattedDay(today);

    let disableToday = this.checkAvailableHoursDay(todayName, false);
    let disableTomorrow = this.checkAvailableHoursDay(tomorrowName, true);
    if (closingDays && closingDays.length > 0) {
      closingDays.map(period => {
        const { day1, day2 } = period;
        const startDate = moment(day1, 'YYYY-MM-DD');
        const endDate = moment(day2, 'YYYY-MM-DD');

        const tomorrowIsDisabled = this.checkDayIsDisabled(
          tomorrow,
          startDate,
          endDate
        );
        const todayIsDisabled = this.checkDayIsDisabled(
          today,
          startDate,
          endDate
        );
        disableToday = todayIsDisabled || disableToday ? true : false;
        disableTomorrow = tomorrowIsDisabled || disableTomorrow ? true : false;
      });
    }

    if (closeDay && closeDay.length) {
      closeDay.map(day => {
        disableTomorrow =
          day === tomorrowName || disableTomorrow ? true : false;
        disableToday = day === todayName || disableToday ? true : false;
      });
    }

    this.setState({
      disableToday: disableToday,
      disableTomorrow: disableTomorrow
    });
  };

  checkDayIsDisabled = (day, startDate, endDate) => {
    return (
      moment(day).isBetween(startDate, endDate) ||
      moment(day).isSame(startDate) ||
      moment(day).isSame(endDate)
    );
  };

  formattedDay = day => {
    return moment(day)
      .format('dddd')
      .toLowerCase();
  };

  checkAvailableHoursDay = (day, tomorrowDay) => {
    // const { openingHours, validationDelay, lessTimes } = this.state;
    const { openingHours, validationDelay } = this.state;
    const hasHours = openingHours[day];
    if (hasHours && hasHours.length) {
      const lastTimeRange = hasHours[hasHours.length - 1];
      const hours =
        lastTimeRange['time2'] === '00:00' ? '23:59' : lastTimeRange['time2'];
      const d = tomorrowDay
        ? moment()
            .add(1, 'days')
            .format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
      const nowPlusdelay = moment().add(validationDelay, 'm');
      const lastHourAvailable = moment(`${d} ${hours}`);
      // .subtract(
      //   lessTimes,
      //   'm'
      // );
      const result = moment(nowPlusdelay).isAfter(lastHourAvailable);
      return result;
    }
    return true;
  };

  selectDayMenuHoursIsStillAvailable = selectDayMenu => {
    const menuDay = moment(selectDayMenu).format('YYYY-MM-DD');
    // get name of the day to search into openingHours object
    const day = moment(selectDayMenu, 'YYYY-MM-DD')
      .format('dddd')
      .toLowerCase();

    // get name of selected day

    this.selectDayName(menuDay, day);
    // check if morning exist or evening

    if (this.state.width <= 769) {
      this.setState({
        mobilePeriod: ''
      });
      this.props.onUpdateMobileDatePeriod('');
    }
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  /**
   * get name of day selected
   */
  selectDayName = (menuDay, day) => {
    const openingHours = this.state.openingHours;
    if (menuDay === today) {
      this.setState(
        {
          selectDay: 'today'
        },
        () => {
          if (
            (!!this.state.disableEvening && !this.state.disableMorning) ||
            (!this.state.disableEvening && !!this.state.disableMorning)
          ) {
            this.pushToHoursView(today);
          }
        }
      );
    } else if (menuDay === tomorrow) {
      this.setState(
        {
          selectDay: 'tomorrow'
        },
        () => {
          if (
            (!!this.state.disableEvening && !this.state.disableMorning) ||
            (!this.state.disableEvening && !!this.state.disableMorning)
          ) {
            this.pushToHoursView(tomorrow);
          }
        }
      );
    } else {
      this.setState(
        {
          selectDay: 'nextDay'
        },
        () => {
          // check if day are one period
          if (!this.state.openingHours) {
            this.context.router.history.push('/heure');
          }
        }
      );
    }
  };

  pushToHoursView = day => {
    if (
      (day !== this.state.userDay && this.state.width >= 769) ||
      (day !== this.state.userDay &&
        this.state.width < 769 &&
        this.state.mobilePeriod)
    ) {
      this.props.onUpdateMobileDatePeriod(
        !this.state.disableMorning ? 'morning' : 'evening'
      );
      this.context.router.history.push('/heure');
    }
  };

  selectDay = (e, day) => {
    e.preventDefault();
    let selectDay;
    if (day === 'today' || day === 'tomorrow') {
      if (day === 'today') {
        selectDay = today;
        this.setState({
          selectPeriod: '',
          disableEvening: '',
          disableMorning: ''
        });
        this.selectDayMenuHoursIsStillAvailable(today);
      } else {
        selectDay = tomorrow;
        this.selectDayMenuHoursIsStillAvailable(tomorrow);
      }

      this.setState({
        selectDay: day,
        mobileNextDate: false,
        selectPeriod: ''
      });
      this.props.onUpdate(selectDay);
      this.context.router.history.push('/heure');
    }

    if (day === 'nextDay') {
      this.selectDayMenuHoursIsStillAvailable(day);
      this.setState({
        selectDay: day,
        mobileNextDate: true,
        mobilePeriod: ''
      });
    }
  };

  displayPopup = (e, bool) => {
    e.preventDefault();
    this.setState({
      mobileNextDate: bool
    });
  };

  displayPopupAfterSelectDay = () => {
    this.setState(
      {
        mobileNextDate: false
      },
      () => this.context.router.history.push('/heure')
    );
  };

  selectPeriod = (e, val) => {
    e.preventDefault();
    this.setState(
      {
        mobilePeriod: val
      },
      () => {
        this.props.onUpdateMobileDatePeriod(val);
        if (this.state.selectDay) {
          this.context.router.history.push('/heure');
        }
      }
    );
  };

  updateNextDate = date => {
    this.props.onUpdate(date);
    this.selectDayMenuHoursIsStillAvailable(date);
  };

  render() {
    const displayAllPeriod =
      !this.state.disableEvening && !this.state.disableMorning ? true : false;

    const calendarUI = isMobile => {
      return (
        <CalendarUi
          onUpdate={this.props.onUpdate}
          val={this.props.val}
          closingDays={this.state.closingDays}
          openingHours={this.props.openingHours}
          menuDay={this.props.menuDay}
          closeDay={this.state.closeDay}
          isMobile={isMobile}
          displayAllPeriod={displayAllPeriod}
          disableToday={this.state.disableToday}
          disableTomorrow={this.state.disableTomorrow}
          userDay={this.props.userDay}
          closePopup={isMobile ? this.displayPopupAfterSelectDay : ''}
          disableAppPeriod={this.state.disableAppPeriod}
          disableApp={this.state.disableApp}
          updateNextDate={this.updateNextDate}
          validationDelay={this.state.validationDelay}
        />
      );
    };
    const timePeriod = (
      <div className="select-period">
        <button
          className={`${
            !this.state.disableMorning && this.state.mobilePeriod === 'morning'
              ? 'is-blue'
              : 'is-blue-line'
          } ${
            this.state.disableMorning ||
            (this.state.disableToday &&
              this.state.disableTomorrow &&
              !this.state.selectDay)
              ? 'disabled'
              : ''
          }`}
          onClick={e => this.selectPeriod(e, 'morning')}
        >
          <FormattedMessage id="hours.morning" defaultMessage="Midi" />
        </button>
        <button
          className={`${
            !this.state.disableEvening && this.state.mobilePeriod === 'evening'
              ? 'is-blue'
              : 'is-blue-line'
          } ${
            this.state.disableEvening ||
            (this.state.disableToday &&
              this.state.disableTomorrow &&
              !this.state.selectDay)
              ? 'disabled'
              : ''
          }`}
          onClick={e => this.selectPeriod(e, 'evening')}
        >
          <FormattedMessage id="hours.evening" defaultMessage="Soir" />
        </button>
      </div>
    );

    const mobile = (
      <div id="calendar-mobile">
        <div className="select-days">
          {this.state.mobileNextDate ? (
            <Popup
              title=""
              content={calendarUI(true)}
              close={this.displayPopup}
            />
          ) : (
            ''
          )}
          <button
            className={`${
              !this.state.disableToday && this.state.selectDay === 'today'
                ? 'is-blue'
                : 'is-blue-line'
            } ${this.state.disableToday ? 'disabled' : ''}`}
            onClick={e => this.selectDay(e, 'today')}
          >
            <FormattedMessage id="calendar.today" defaultMessage="Ajourd'hui" />
          </button>
          <button
            className={`${
              !this.state.disableTomorrow && this.state.selectDay === 'tomorrow'
                ? 'is-blue'
                : 'is-blue-line'
            } ${this.state.disableTomorrow ? 'disabled' : ''}`}
            onClick={e => this.selectDay(e, 'tomorrow')}
          >
            <FormattedMessage id="calendar.tomorrow" defaultMessage="Demain" />
          </button>
          <button
            className={`${
              this.state.selectDay === 'nextDay' ? 'is-blue' : 'is-blue-line'
            }`}
            onClick={e => this.selectDay(e, 'nextDay')}
          >
            <FormattedMessage id="calendar.next" defaultMessage="Plus tard" />
          </button>
        </div>
        {/* !this.state.disableMorning || !this.state.disableEvening
          ? timePeriod
          : '' */}
      </div>
    );

    if (this.state.width > 769) {
      return calendarUI(false);
    } else {
      return mobile;
    }
  }

  static defaultProps = {
    closingDays: [],
    menuDay: '',
    openingHours: {},
    mobilePeriod: '',
    validationDelay: 0,
    closeDay: [],
    disableApp: false,
    disableMobilePeriod: ''
  };
}

PickDate.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  closingDays: PropTypes.array,
  userDay: PropTypes.string,
  openingHours: PropTypes.object.isRequired,
  mobilePeriod: PropTypes.string,
  onUpdateMobileDatePeriod: PropTypes.func.isRequired,
  validationDelay: PropTypes.number.isRequired,
  closeDay: PropTypes.array.isRequired,
  disableAppPeriod: PropTypes.object,
  disableApp: PropTypes.bool.isRequired,
  disableMobilePeriod: PropTypes.string
};

export default PickDate;
