import React, { Component } from 'react';

class RadioTimePicker extends Component {
	constructor(props) {
		super(props);
		this.state = { timeslots: props.data, selectedHour: props.selectedHour };
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		const selectedValue = event.target.value;
		this.props.onChange(selectedValue);
	}

	render() {
		return (
			<div className="timeslots">
				{this.state.timeslots.map((slot, i) => {
					return slot.length ? (
						<div key={`list-item-${i}`} className="timeslots__content">
							<ol key={`list-${i}`} className="timeslots__list">
								{slot.map((hour, j) => {
									const disabled =
										this.props.frozenSlots[hour] &&
										this.props.frozenSlots[hour].freeze;
									return (
										<li
											key={`list-item-${i}-${j}`}
											className={`timeslots__list__item ${
												this.state.selectedHour === hour
													? 'timeslots__list__item--active'
													: null
											} ${
												disabled ? 'timeslots__list__item--disabled' : null
											}`}>
											<input
												type="radio"
												name="time"
												id={`radio-${i}-${j}`}
												onChange={this.handleChange}
												value={hour}
												disabled={disabled}
											/>
											<label htmlFor={`radio-${i}-${j}`}>{hour}</label>
										</li>
									);
								})}
							</ol>
						</div>
					) : null;
				})}
			</div>
		);
	}
}

export default RadioTimePicker;
