import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from './../common/Popup';
import { FormattedMessage } from 'react-intl';
import { generateNbrGuests } from './../../utils/generateNbrGuests';

class NbGuest extends Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      activeNbGuest: false,
      indexActive: '',
      nbGuest: props.userDiner !== 'Couverts' ? props.userDiner : 'default',
      nbGuests: [],
      nbGuestMore: 7,
      nbGuestPopup: 7,
      displayPopup: false,
      width: window.innerWidth
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    if (this.props.userDiner > 7) {
      this.setState({
        nbGuestMore: this.props.userDiner
      });
    }
    if (this.props.maxGuests) {
      this.generateOptions(this.props.maxGuests);
    }
  }

  handlerNbGuest = e => {
    e.preventDefault();
    this.setState(
      {
        nbGuest: e.target.value,
        activeNbGuest: true,
        indexActive: e.target.value
      },
      () => {
        this.onUpdateNbGuest();
        this.context.router.history.push('/contact');
      }
    );
  };

  handlerMore = e => {
    this.setState({ nbGuest: e.target.value });
  };

  onUpdateNbGuest = () => {
    this.props.onUpdate(this.state.nbGuest);
  };

  displayPopup = (e, bool) => {
    e.preventDefault();
    this.setState({
      displayPopup: bool
    });
  };

  handleInputChange = e => {
    e.preventDefault();
    this.setState({
      nbGuestPopup: parseInt(e.target.value, 10),
    });
  };

  submitPopup = e => {
    e.preventDefault();
    this.setState(
      {
        nbGuest: this.state.nbGuestPopup,
        nbGuestPopup: '',
        displayPopup: false
      },
      () => {
        this.onUpdateNbGuest();
        this.context.router.history.push('/contact');
      }
    );
  };

  generateOptions(nbrGuests) {
    const allGuests = generateNbrGuests(nbrGuests);
    const guests = allGuests.slice(0, 6);

    this.setState({
      nbGuests: guests,
      nbGuestMore: allGuests.length < 7 ? null :  7,
    });
  }

  render() {
    const { maxGuests } = this.props;
    const inputNbGuest = (
      <form onSubmit={e => this.submitPopup(e)}>
        <input
          className="input"
          name="nbGuest"
          onChange={this.handleInputChange}
          type="number"
          min="1"
          max={maxGuests}
          value={this.state.nbGuestPopup || ''}
          required
        />
        <button className="is-red">
          <FormattedMessage id="common.validate" defaultMessage="valider" />
        </button>
      </form>
    );
    return (
      <React.Fragment>
        <div id="nbGuest">
          {this.state.width > 769 ? (
            <h3>
              <FormattedMessage
                id="nbGuest.titleDesktop"
                defaultMessage="Combien de personne(s) ?"
              />
            </h3>
          ) : (
            ''
          )}
          <ul className="pick">
            {this.state.nbGuests.map((nbGuest, i) => {
              return (
                <button
                  key={i}
                  value={nbGuest}
                  onClick={this.handlerNbGuest}
                  className={`${
                    this.state.nbGuest !== 'default' &&
                    Math.floor(this.state.nbGuest) === nbGuest
                      ? 'is-blue'
                      : 'is-blue-line'
                  }`}
                >
                  {nbGuest}
                </button>
              );
            })}
            {this.state.nbGuestMore >= 7 ? (
              <button
                onClick={e => this.displayPopup(e, true)}
                className={`more-guest ${
                  this.state.nbGuest !== 'default' &&
                  Math.floor(this.state.nbGuest) >= 7
                    ? 'is-blue'
                    : 'is-blue-line'
                }`}
              >
                <p>
                  {this.state.nbGuestMore >= 7 ? (
                    <span className="more-0">+</span>
                  ) : (
                    ''
                  )}
                  {this.state.nbGuestMore}
                </p>
              </button>
            ) : (
              ''
            )}

            {!!this.state.displayPopup ? (
              <Popup
                title={
                  <FormattedMessage
                    id="nbGuest.popupTitle"
                    defaultMessage="Entrez le nombre de couverts"
                  />
                }
                content={inputNbGuest}
                close={e => this.displayPopup(e, false)}
              />
            ) : (
              ''
            )}
          </ul>
        </div>
      </React.Fragment>
    );
  }
  static defaultProps = {
    userDiner: '0'
  };
}

NbGuest.propTypes = {
  userDiner: PropTypes.string
};

export default NbGuest;
