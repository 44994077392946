import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import RadioTimePicker from './RadioTimePicker';
import PropTypes from 'prop-types';
import moment from 'moment';

class PickTime extends Component {
	static contextTypes = {
		router: PropTypes.object,
	};
	constructor(props) {
		super(props);
		this.state = {
			selectedHour: props.userTime ? moment(props.userTime, 'HH:mm') : null,
			validationDelay: props.validationDelay,
			userDay: props.userDay,
			openingHours: props.openingHours,
			selectedDay: [],
		};
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.validationDelay !== this.state.validationDelay ||
			nextProps.openingHours !== this.state.openingHours ||
			nextProps.userDay !== this.state.userDay
		) {
			this.setState({
				validationDelay: nextProps.validationDelay,
				openingHours: nextProps.openingHours,
				userDay: nextProps.userDay,
			});
		}
	}

	componentDidMount() {
		const selectedDay = this.findSelectedDay();
		this.setState({ selectedDay });
	}

	handleTimeChange = value => {
		const hours = value.split(':')[0];
		const minutes = value.split(':')[1];

		const selectedHour = moment();
		selectedHour.set('hours', hours);
		selectedHour.set('minutes', minutes);

		this.setState({ selectedHour }, () => this.sendData());
	};

	findSelectedDay = () => {
		const { openingHours, userDay } = this.props;
		const day = moment(userDay).format('dddd').toLowerCase();
		const formattedDays = Object.entries(openingHours);

		const findDay = formattedDays.find(dayInSchedule => {
			if (dayInSchedule[0] === day) return dayInSchedule;
		});
		if (findDay) {
			findDay[1].forEach(element => {
				if (element.time2 === '00:00') {
					element.time2 = '23:59';
				}
			});
		}
		return findDay;
	};

	checkDataSent = selectedHour => {
		const { validationDelay, userDay } = this.state;
		const nowWithAddDelay = moment().add(validationDelay, 'm');

		if (selectedHour) {
			const formateSelectedHour = selectedHour.format('HH:mm');
			const selectedDayNHour = moment(`${userDay} ${formateSelectedHour}`);
			const slcDayIsToday = this.checkDayIsToday();
			const lastHourAvailable = this.findLastHourAvailable();
			if (slcDayIsToday) {
				if (selectedDayNHour.isAfter(lastHourAvailable)) return null;
			}
			const slctDayNlastHourAvailable = moment(
				`${userDay} ${moment(lastHourAvailable).format('HH:mm')}`
			);
			return (
				selectedDayNHour.isAfter(nowWithAddDelay) &&
				selectedDayNHour.isSameOrBefore(slctDayNlastHourAvailable)
			);
		}
		return null;
	};

	checkDayIsToday = () => {
		const { userDay } = this.state;
		const today = moment().format('YYYY-MM-DD');
		return moment(today).isSame(userDay);
	};

	findLastHourAvailable = () => {
		const { selectedDay } = this.state;
		const lastTimeRange = selectedDay[1][selectedDay[1].length - 1];
		const secondHourLess15Mins = lastTimeRange.time2;
		return moment(secondHourLess15Mins, 'HH:mm');
	};

	sendData = () => {
		const { selectedHour } = this.state;
		const isHourAvailable = this.checkDataSent(selectedHour);
		if (selectedHour && isHourAvailable) {
			const selectTime = this.formateTime();
			this.props.onUpdate(selectTime);
			this.context.router.history.push('/couverts');
		} else {
			this.setState({ selectedHour: null }, () => this.props.onUpdate(''));
		}
	};

	formateTime() {
		const { selectedHour } = this.state;
		const hour = moment(selectedHour).format('HH');
		const minutes = moment(selectedHour).format('mm');
		return `${hour}h${minutes}`;
	}

	/**
	 * Generates available time slots for a given day
	 *
	 * @param {string} start - start time as HH:mm
	 * @param {string} end - end time as HH:mm
	 * @param {number} interval - interval between each generated time
	 * @param {string} separator - separator between hours and minutes
	 *
	 * @returns {Array} array of times between start and end
	 */
	generateTimeSlots(start, end, interval = 15, separator = ':') {
		const validationDelay = this.state.validationDelay;
		const now = moment().add(validationDelay, 'm');

		let result = [];

		const isToday = this.checkDayIsToday();

		// quit if is today and end time is already reached
		if (isToday && now.format('HH:mm') > end) {
			return [];
		}

		// if is today and start time is already reached
		// reassign start with next time available after now
		if (isToday && now.format('HH:mm') > start) {
			const minutes = now.format('mm');
			// Use interval to be in [0, 15, 30, 45, 60];
			const nextTimeAvailable = Math.ceil(minutes / interval) * interval;
			start = now.set('minute', nextTimeAvailable).format('HH:mm');
		}

		const startSplited = start.split(':').map(x => +x);
		const startHour = startSplited[0];
		const startMinute = startSplited[1];

		const endSplited = end.split(':').map(x => +x);
		const endHour = endSplited[0];
		const endMinute = endSplited[1];

		for (let i = startHour; i <= endHour; i++) {
			let minutes = i === startHour ? startMinute : 0;

			for (let j = minutes; j < 60; j += interval) {
				if (!(i === endHour && j > endMinute)) {
					result.push(
						[i < 10 ? '0' + i : i, j < 10 ? '0' + j : j].join(separator)
					);
				}
			}
		}
		return result;
	}

	render() {
		const { selectedDay } = this.state;
		const selectedHour = moment(this.state.selectedHour).format('HH:mm');
		let listTimeRange = [];
		let listTimeData = [];

		if (selectedDay && selectedDay.length) {
			const self = this;
			listTimeRange = selectedDay[1];
			listTimeData = listTimeRange.map(slot => {
				return self.generateTimeSlots(slot.time1, slot.time2);
			});
		}

		return (
			<div>
				<div className="content-hours">
					{selectedDay && selectedDay.length ? (
						<div>
							<RadioTimePicker
								data={listTimeData}
								onChange={this.handleTimeChange}
								selectedHour={selectedHour}
								frozenSlots={this.props.slotFreeze[this.state.userDay] || {}}
							/>
						</div>
					) : (
						<div className="container-display-message">
							<h4>
								<FormattedMessage
									id="hours.selectDay"
									defaultMessage="Veuillez sélectionner un jour"
								/>
							</h4>
						</div>
					)}
				</div>
			</div>
		);
	}
	static defaultProps = {
		openingHours: {},
	};
}

PickTime.propTypes = {
	openingHours: PropTypes.object.isRequired,
	userDay: PropTypes.string,
	onUpdate: PropTypes.func.isRequired,
};

export default PickTime;
